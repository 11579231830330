import { useEffect } from "react";
import React from "react";
import "./App.css";

import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import Web3Modal from "web3modal";
import * as alertify from "alertifyjs";
import "alertifyjs/build/css/alertify.css";

import { PORTALSAbi } from "./contracts/PORTALSAbi.js";
import { TODNFTAbi } from "./contracts/TODNFTAbi.js";

import refreshButtonImage from "./images/refreshButton.svg";
import logo from "./images/text-logo-wide.svg";

let web3Modal;
let provider;
let selectedAccount;

var selectedTODImages = [];
var todNFTWalletIDs = [];
var femaleTodNFTWalletIds = [];
var claimedTodsIds = [];
var builtImages = [];

const todNFTContractAddress = "0x71EAa691b6e5D5E75a3ebb36D4f87CBfb23C87b0";
const portalsNFTContractAddress = "0xD8e3Fa0B42F1de6dec81a540e7fb2388267D86d0";

const etherscanURL = "https://etherscan.io/";

function App() {
    //testing function ignore
    async function Test() {
        console.log(builtImages);
    }

    //Connect all necessary data to webpage
    async function onConnect() {
        const providerOptions = {
            walletconnect: {
            package: WalletConnectProvider,
            options: { infuraId: "c6fe8488d888438d8d9cd662bbd7ac52" },
            },
        };
        const web3Modal = new Web3Modal({
            cacheProvider: false,
            providerOptions,
            disableInjectedProvider: false,
            //Set modal pop-up colour sceme
            theme: {
            background: "rgb(39, 49, 56)",
            main: "rgb(199, 199, 199)",
            secondary: "rgb(136, 136, 136)",
            border: "rgba(195, 195, 195, 0.14)",
            hover: "rgb(16, 26, 32)",
            },
        });
        console.log("Opening a dialog", web3Modal);
        try {
            //All the connect code
            provider = await web3Modal.connect();
            const web3 = new Web3(provider);
            web3.eth.transactionConfirmationBlocks = 2;
            detectEthereumNetwork();
        } catch (e) {
            console.log("Could not get a wallet connection", e);
            return;
        }
        var connectButton = document.getElementById("connectButton");
        connectButton.innerHTML = "Connected";
        connectButton.classList.toggle("disableButton");
        document.querySelector(".sep").style.display = "none";
        document.querySelector(".intro").style.display = "block";
        document.querySelector(".portalsTitle").style.color = "#93eb45"
        document.querySelector(".intro").style.maxWidth = "1280px";
        document.body.style.height = "auto";

        document.querySelector(".refresh-wallet-button").style.display = "inline";

        buildWalletData();
    }

    //Check if connected to mainnet (currently set for rinkeby for testing)
    async function detectEthereumNetwork() {
        const web3 = new Web3(provider);
        web3.eth.transactionConfirmationBlocks = 2;
        web3.eth.net.getNetworkType().then(async (netId) => {
            console.log(netId);
            if (netId !== "main") {
                alertify.alert("You are on the wrong network, please switch to Mainnet, Current network: " + netId).set({ title: "An error has occured" });
                onDisconnect();
            }
        });
    }

    //Build all user wallet specific data (Account number, wallet balance)
    async function buildWalletData() {
        const web3 = new Web3(provider);
        web3.eth.transactionConfirmationBlocks = 2;
        console.log("Web3 instance is", web3);

        selectedTODImages = [];

        //Get Account
        const accounts = await web3.eth.getAccounts();
        console.log("Got accounts", accounts);
        var selectedAccount = accounts[0];

        //Update HTML
        web3.eth.getBalance(selectedAccount).then((result) => {
            const userWalletBalance = web3.utils.fromWei(result, "ether");
            document.querySelector(".showBalance").innerHTML = toFixed(userWalletBalance, 5);
        });
        document.querySelector(".showAccount").innerHTML = selectedAccount;

        getWalletInventory();
    }

    //Get all wallet and token specific data (NFT balances)
    async function getWalletInventory() {
        const reloadButton = document.getElementById("refreshButton")
        setButtonTimeout(reloadButton)
        var disconnectButton = document.getElementById("disconnectButton");
        setButtonTimeout(disconnectButton)
        loadingImage();

        document.querySelector(".noOddysFound").style.display = "none";

        const web3 = new Web3(provider);
        //Get Accounts and contract variables
        const accounts = await web3.eth.getAccounts();
        selectedAccount = accounts[0];

        //Get Contracts
        const todNFTContract = new web3.eth.Contract(
            TODNFTAbi,
            todNFTContractAddress
        );
        const portalsContract = new web3.eth.Contract(
            PORTALSAbi,
            portalsNFTContractAddress
        );

        //TOTAL MINTED
        //overall
        var totalPortalTODSupply = await portalsContract.methods.totalSupply().call({});
        document.querySelector(".showTotalPortalTODSupply").innerHTML = totalPortalTODSupply;
        //public
        var totalPublicPortalTODSupply = await portalsContract.methods.publicMinted().call({});
        document.querySelector(".showTotalPublicPortalTODSupply").innerHTML = totalPublicPortalTODSupply;

        //get all of the users TOD NFTS
        todNFTWalletIDs = [];
        var todNFTWalletBalance = [];
        femaleTodNFTWalletIds = [];
        var femaleTodNFTWalletBalance = [];
        claimedTodsIds = [];

        femaleTodNFTWalletIds = await portalsContract.methods.tokensOfOwner(selectedAccount).call({});
        femaleTodNFTWalletBalance = femaleTodNFTWalletIds.length;
        if (femaleTodNFTWalletBalance === 0) {
            femaleTodNFTWalletIds = "No female TOD NFTs found in wallet";
            femaleTodNFTWalletBalance = 0;
            console.log("No female TOD NFTs found in wallet");
        }

        document.querySelector(".showTodNFTBalance").innerHTML = todNFTWalletBalance;
        document.querySelector(".showTodNFTIDs").innerHTML = todNFTWalletIDs;
        document.querySelector(".showFemaleTodNFTBalance").innerHTML = femaleTodNFTWalletBalance;
        document.querySelector(".showFemaleTodNFTIDs").innerHTML = femaleTodNFTWalletIds;

        try {

            document.querySelectorAll("todNFTsStakedImagesID").forEach(e => e.remove());

            todNFTWalletIDs = await todNFTContract.methods.tokensOfOwner(selectedAccount).call({});
            todNFTWalletBalance = todNFTWalletIDs.length;

            document.querySelector(".showTodNFTBalance").innerHTML =todNFTWalletBalance;
            document.querySelector(".showTodNFTIDs").innerHTML = todNFTWalletIDs;

            console.log("LOADING ODDYS......");

            for (let [i, val] of todNFTWalletIDs.entries()) {
                var checkClaim = await portalsContract.methods.claimedByTokenId(val).call({});
                if (checkClaim === true) {
                    addOrRemove(claimedTodsIds, val);
                }
            }

            var loadingClassName = document.getElementById("loadingImage");
            loadingClassName.remove();

            console.log("ODDYS LOADED......");

            console.log("Found already claimed TODs");
            console.log(claimedTodsIds);

            getTodImages(todNFTWalletIDs);
        } catch (err) {

            var loadingClassName = document.getElementById("loadingImage");
            loadingClassName.remove();
            todNFTWalletIDs = "No TOD NFTs found in wallet";
            todNFTWalletBalance = 0;
            console.log("No TOD NFTs found in wallet");

            document.querySelector(".showTodNFTBalance").innerHTML =
            todNFTWalletBalance;
            document.querySelector(".showTodNFTIDs").innerHTML = todNFTWalletIDs;

            document.querySelector(".noOddysFound").style.display = "inline";
            document.querySelector(".noOddysFound").innerHTML ="No Oddys Found in Wallet";
        }
    }

    async function getTodImages(todNFTWalletIDs) {
        //Load tod images into page
        var todWalletImagesDiv = ".todNFTsStakedImages";
        var todWalletImagesUL = "todWalletImageList";
        var todWalletul = document.createElement("ul");
        todWalletul.setAttribute("id", todWalletImagesUL);
        document.querySelector(todWalletImagesDiv).appendChild(todWalletul);
        for (let [i, val] of todNFTWalletIDs.entries()) {
            createButtons(val, todWalletImagesDiv, todWalletImagesUL);
        }
    }

    //get NFT image directly from contract/IPFS
    async function createButtons(tokenID, divName, ulName) {
        var imagePath = require("./images/TOD_images_thumbs/" + tokenID + ".png");

        var img = new Image();
        img.onload = function () {
            var htmlImg = document.createElement("img");
            const tokenContainer = document.createElement("div");
            const textSel = document.createElement("span");
            var li = document.createElement("li");
            htmlImg.setAttribute("src", imagePath);
            htmlImg.setAttribute("id", divName + tokenID);

            htmlImg.onclick = function () {
                onButtonClick(tokenID, divName);
            };

            tokenContainer.setAttribute("class", "tod-cont");
            textSel.setAttribute("class", "sel-text");
            textSel.innerHTML = "ODDY SELECTED";
            li.appendChild(tokenContainer);
            tokenContainer.appendChild(htmlImg);
            tokenContainer.appendChild(textSel);
            var list = document.getElementById(ulName);
            list.appendChild(li);
            
            if (claimedTodsIds.includes(tokenID) === true) {
                htmlImg.setAttribute("src", require("./images/portal.gif"));
                htmlImg.onclick = null;
            }
        };

        img.src = imagePath;
    }

    //get NFT image directly from contract/IPFS
    async function loadingImage() {
        var imagePath = require("./images/Loading02.gif");

        var img = new Image();
        img.onload = function () {
            var htmlImg = document.createElement("img");
            htmlImg.setAttribute("src", imagePath);
            htmlImg.setAttribute("id", "loadingImage");
            var loadingClassName = document.getElementById("loadingOddysID");
            loadingClassName.appendChild(htmlImg);
        };
        img.src = imagePath;
        return img;
    }

    //Mint Public Portal NFTs
    async function MintPublic() {
        //Get Accounts and contract variables
        const web3 = new Web3(provider);
        web3.eth.transactionConfirmationBlocks = 3
        const accounts = await web3.eth.getAccounts();
        selectedAccount = accounts[0];

        var mintAmount = document.getElementById("mintNFTAmountID").value;

        //Get Contracts
        const portalsContract = new web3.eth.Contract(PORTALSAbi, portalsNFTContractAddress);

        //Call price of each token
        var pricePerToken = await portalsContract.methods.cost().call({});
        //Multiply price by mint amount
        var totalPrice = web3.utils.toBN(pricePerToken).mul(web3.utils.toBN(mintAmount));

        //Send transaction
        portalsContract.methods.publicMint(mintAmount)
            .estimateGas({
                from: selectedAccount, 
                value: totalPrice
        })
        .then(function(gasAmount){
        portalsContract.methods.publicMint(mintAmount)
        .send({
            from: selectedAccount, 
            value: totalPrice, 
            gas: gasAmount})
        .on("transactionHash", function (hash) {
            console.log('View Transaction Detail on Etherscan:' + etherscanURL + "/tx/" + hash + hash.substr(0,20))
        })
        .on('confirmation', function(confirmationNumber, receipt){
            console.log(confirmationNumber, receipt)
            if (confirmationNumber === 0) {
                const grabbed = document.querySelectorAll(".sel-text");
                grabbed.forEach((s) => {
                    s.remove();
                });
                document.querySelectorAll("todNFTsStakedImagesID").forEach(e => e.remove());
                try {
                    for (let [i, val] of todNFTWalletIDs.entries()) {
                        var image = document.getElementById(".todNFTsStakedImages" + val);
                        image.remove();
                    }
                    getWalletInventory();
                } catch {
                }
            }
        })
        })
        //Catch all errors in the contract and raise alert
        .catch(function (error) {
            if (error.message.indexOf("Public sale has not begun yet") > -1) {
                alertify.alert("Sorry, The public sale has not started!").set({title:"An error has occured"});
            }
            if (error.message.indexOf("Can not mint 0") > -1) {
                alertify.alert("Sorry, You must enter an amount to mint!").set({title:"An error has occured"});
            }
            if (error.message.indexOf("Can not mint this many") > -1) {
                alertify.alert("Sorry, You cannot mint this many in a single transaction!").set({title:"An error has occured"});
            }
            if (error.message.indexOf("Can not go over public supply") > -1) {
                alertify.alert("Sorry, You cannot mint this many! It will exceed the total supply").set({title:"An error has occured"});
            }
            if (error.message.indexOf("Wrong amount") > -1) {
                alertify.alert("Sorry, You have entered the wrong price for this many tokens").set({title:"An error has occured"});
            }
            else if (error.message.indexOf("Can not go over max supply") > -1) {
                alertify.alert("Sorry, You can not mint more than the max supply").set({title:"An error has occured"});
            }
        });
    }

    //Mint Public Portal NFTs
    async function claimWithOddy() {
        //Get Accounts and contract variables
        const web3 = new Web3(provider);
        web3.eth.transactionConfirmationBlocks = 3
        const accounts = await web3.eth.getAccounts();
        selectedAccount = accounts[0];

        //Get Contracts
        const portalsContract = new web3.eth.Contract(PORTALSAbi, portalsNFTContractAddress);

        console.log(selectedTODImages)

        //Send transaction
        portalsContract.methods.mintWithTOD(selectedTODImages)
            .estimateGas({
                from: selectedAccount, 
        })
        .then(function(gasAmount){
        portalsContract.methods.mintWithTOD(selectedTODImages)
            .send({
                from: selectedAccount, 
                gas: gasAmount})
            .on("transactionHash", function (hash) {
                console.log('View Transaction Detail on Etherscan:' + etherscanURL + "/tx/" + hash + hash.substr(0,20))
            })
            .on('confirmation', function(confirmationNumber, receipt){
                console.log(confirmationNumber, receipt)
                if (confirmationNumber === 0) {
                    const grabbed = document.querySelectorAll(".sel-text");
                    grabbed.forEach((s) => {
                        s.remove();
                    });
                    document.querySelectorAll("todNFTsStakedImagesID").forEach(e => e.remove());
                    try {
                        for (let [i, val] of todNFTWalletIDs.entries()) {
                            var image = document.getElementById(".todNFTsStakedImages" + val);
                            image.remove();
                        }
                        getWalletInventory();
                    } catch {
                    }
                }
            })
        })
        //Catch all errors in the contract and raise alert
        .catch(function (error) {
            if (error.message.indexOf("Public sale has not begun yet") > -1) {
                alertify.alert("Sorry, The claim has not started!").set({title:"An error has occured"});
            }
            if (error.message.indexOf("Can not mint 0") > -1) {
                alertify.alert("Sorry, You must select an Oddy to send through the portal").set({title:"An error has occured"});
            }
            if (error.message.indexOf("Token ID invalid") > -1) {
                alertify.alert("Sorry, this token ID is invalid!").set({title:"An error has occured"});
            }
            if (error.message.indexOf("Not the owner of this ODDY") > -1) {
                alertify.alert("Sorry, You are not the owner of this Oddy").set({title:"An error has occured"});
            }
            else if (error.message.indexOf("You have already claimed for one of these tokens") > -1) {
                alertify.alert("Sorry, You have already claimed for one of these Oddys").set({title:"An error has occured"});
            }
        });
    }

    // Check if input ID is claimed
    async function checkClaimed() {
        //Get Accounts and contract variables
        const web3 = new Web3(provider);
        const accounts = web3.eth.getAccounts();
        selectedAccount = accounts[0];

        //Get Contract
        const portalsContract = new web3.eth.Contract(PORTALSAbi,portalsNFTContractAddress);

        // Get & validate input value
        const idToCheck = document.getElementById("idInput").value;
        const result = document.querySelector(".checkResult");
        document.getElementById("thumb-cont").innerHTML = "";

            if ( idToCheck > 5000 || idToCheck < 1 || idToCheck === 0 || idToCheck === isNaN) {
                document.querySelector(".checkResult").innerHTML =
                    "Please input a valid number between 1 and 5000";
                document.getElementById("thumb-cont").innerHTML = " ? ";
            } else {
            // Generate token thumb
            const imagePath = require("./images/TOD_images_thumbs/" + idToCheck + ".png");
            const img = new Image();
            img.onload = function () {
                const checkThumb = document.createElement("img");
                const thumbCont = document.getElementById("thumb-cont");
                checkThumb.setAttribute("src", imagePath);
                checkThumb.setAttribute("class", "tod-cont");
                thumbCont.appendChild(checkThumb);
            };

            img.src = imagePath;

            const idLink = "https://opensea.io/assets/ethereum/0x71eaa691b6e5d5e75a3ebb36d4f87cbfb23c87b0/" + idToCheck;
            const osLink = "https://opensea.io/collection/the-odd-dystrict-official/";

            // Check input value : claimed or not
            let checkClaim = await portalsContract.methods.claimedByTokenId(idToCheck).call({});

                if (checkClaim) {
                    result.innerHTML = "Oddy #" + idToCheck + " has already been claimed.";
                    const osBuyLink = document.createElement("a");
                    osBuyLink.innerHTML = "Find another on OpenSea.";
                    osBuyLink.classList.add("os-link");
                    osBuyLink.setAttribute("href", osLink);
                    osBuyLink.setAttribute("target", "_blank");
                    result.appendChild(osBuyLink);
                } else {
                    result.innerHTML =
                    "Oddy #" + idToCheck + " is still available to claim a female Oddy!";
                    const osBuyLink = document.createElement("a");
                    osBuyLink.innerHTML = "Buy it on OpenSea.";
                    osBuyLink.classList.add("os-link");
                    osBuyLink.setAttribute("href", idLink);
                    osBuyLink.setAttribute("target", "_blank");
                    result.appendChild(osBuyLink);
                }
            }
    }

    //Add and remove items from array
    function addOrRemove(array, value) {
        var index = array.indexOf(value);

        if (index === -1) {
            array.push(value);
        } else {
            array.splice(index, 1);
        }
    }

    //Button click function
    async function onButtonClick(imageId, divName) {
        var image = document.getElementById(divName + imageId);

        addOrRemove(selectedTODImages, imageId);
        console.log(selectedTODImages);
        console.log(imageId);

        image.classList.toggle("selected");
    }

    //Disconnect wallet, this doesnt actually disconnect the wallet due to new security features.
    //Please see https://ethereum.stackexchange.com/questions/83914/how-to-disconnect-metamask-wallet-using-web3modal
    async function onDisconnect() {
        console.log("Killing the wallet connection", provider);

        if (provider.disconnect) {
            await provider.disconnect();
            await web3Modal.clearCachedProvider();
            provider = null;
        }

        selectedAccount = null;

        //Hide UI
        // document.querySelector(".logo-link").style.display = "block";
        // document.querySelector(".link-text").style.display = "none";
        // document.querySelector(".link-text").style.visibility = "hidden";
        document.querySelector(".sep").style.display = "block";
        document.body.style.height = "100vh";
        var connectButton = document.getElementById("connectButton");
        connectButton.innerHTML = "Connect";
        connectButton.classList.toggle("disableButton");
        document.querySelector(".intro").style.display = "none";
        document.querySelector(".portalsTitle").style.color = "#fae628"
        document.querySelector(".refresh-wallet-button").style.display = "none";

        const grabbed = document.querySelectorAll(".sel-text");
        grabbed.forEach((s) => {
            s.remove();
        });
        document.querySelectorAll("todNFTsStakedImagesID").forEach(e => e.remove());
        try {
            for (let [i, val] of todNFTWalletIDs.entries()) {
                var image = document.getElementById(".todNFTsStakedImages" + val);
                image.remove();
            }
        } catch {
        }
    }

    //fixed decimal helper
    function toFixed(num, fixed) {
        var re = new RegExp("^-?\\d+(?:.\\d{0," + (fixed || -1) + "})?");
        return num.toString().match(re)[0];
    }

    //Select all oddy images
    function selectAllOddys() {
        const filteredArray = todNFTWalletIDs.filter(function (x) {
            return claimedTodsIds.indexOf(x) < 0;
        });

        selectedTODImages = [];
        for (let [i, val] of filteredArray.entries()) {
            var image = document.getElementById(".todNFTsStakedImages" + val);
            image.classList.add("selected");
            addOrRemove(selectedTODImages, val);
        }
        console.log(selectedTODImages);
    }

    //Deselect all oddy images
    function deselectAllOddys() {
        for (let [i, val] of todNFTWalletIDs.entries()) {
            var image = document.getElementById(".todNFTsStakedImages" + val);
            image.classList.remove("selected");
            addOrRemove(selectedTODImages, val);
        };
        selectedTODImages = [];
        console.log(selectedTODImages);
    }

    async function reloadComponent() {
        const reloadButton = document.getElementById("refreshButton")
        setButtonTimeout(reloadButton)
        const grabbed = document.querySelectorAll(".sel-text");
        grabbed.forEach((s) => {
            s.remove();
        });
        document.querySelectorAll("todNFTsStakedImagesID").forEach(e => e.remove());
        try {
            for (let [i, val] of todNFTWalletIDs.entries()) {
                var image = document.getElementById(".todNFTsStakedImages" + val);
                image.remove();
            }
            getWalletInventory();
        } catch {
        }
    }

    function setButtonTimeout(button) {
        button.disabled = true;
        setTimeout(function(){
            button.disabled = false;
            console.log('UI loading, please wait')
        }, 10000)
      }

    //BUTTONS
    const testButton = () => {
        return (
            <button onClick={Test} className="cta-button standard-wallet-button" id="testButton">
                Test
            </button>
        );
    };

    const connectWalletButton = () => {
        return (
            <button onClick={onConnect} className="cta-button connect-wallet-button" id="connectButton">
                Connect Wallet
            </button>
        );
    };

    const refreshWalletButton = () => {
        return (
            <button onClick={reloadComponent} className="cta-button refresh-wallet-button" id="refreshButton">
                Reload Wallet
                <img src={refreshButtonImage} className="rotate" alt="Refresh Button"/>
            </button>
        );
    };

    const disconnectWalletButton = () => {
        return (
            <button onClick={onDisconnect} className="cta-button disconnect-wallet-button" id="disconnectButton">
                Disconnect Wallet
            </button>
        );
    };

    const MintPublicButton = () => {
        return (
            <button onClick={MintPublic} className="cta-button standard-wallet-button" id="mintButton">
                Mint Public
            </button>
        );
    };

    const selectAllOddysButton = () => {
        return (
            <button onClick={selectAllOddys} className="cta-button standard-wallet-button sc-btns" id="mintButton">
                Select All
            </button>
        );
    };

    const deselectAllOddysButton = () => {
        return (
            <button onClick={deselectAllOddys} className="cta-button standard-wallet-button sc-btns" id="mintButton">
                Clear Selection
            </button>
        );
    };

    const claimWithOddyButton = () => {
        return (
            <button onClick={claimWithOddy} className="cta-button standard-wallet-button" id="mintButton">
                Claim Materials
            </button>
        );
    };

    const checkClaimedButton = () => {
        return (
          <button
            onClick={checkClaimed} className="cta-button standard-wallet-button" id="mintButton">
                Check Token ID
          </button>
        );
    };

    //to run on startup, all initial settings and checks
    useEffect(() => {
        //Hide UI
        document.querySelector(".connect-wallet-button").innerHTML = "Connect";
        document.querySelector(".intro").style.display = "none";

        document.querySelector(".refresh-wallet-button").style.display = "none";

        var disconnectButton = document.querySelector(".connect-wallet-button");
        disconnectButton.classList.remove("disableButton");

        const checkWalletIsConnected = () => {
            if (typeof window.ethereum !== "undefined") {
            console.log("Metamask is installed");
            } else {
            console.log("Please install Metamask");
            }
        };
        checkWalletIsConnected();
    }, []);

    return (
    //UI code
    <>
    <div className="main-app">
        <a href="https://the-odd-dystrict.com" className="logo-link" target="blank">
        <img src={logo} className="tod-logo" alt="The Odd Dystrict"></img>
        </a>
        <h1 className="portalsTitle">Portals Collection 01</h1>
        <div className="sep">/ / / / / / / / / / / /</div>
        {connectWalletButton()}
        {refreshWalletButton()}
        <div className="intro">
            <div className="walletInfoGroup">
                <h2 className="subhead">Connected Wallet Info:</h2>
                <p className="col">
                    <b>Wallet Address: </b>
                    <span className="showAccount"></span>
                </p>
                <p className="col">
                    <b>Account Balance: </b>
                    <span className="row">
                    <span className="showBalance"></span>
                    &nbsp;<em> ETH</em>
                    </span>
                </p>
                <p className="col">
                    <b>TOD Balance: </b>
                    <span className="showTodNFTBalance"></span>
                </p>
                <p className="col">
                    <b>TOD IDs: </b>
                    <span className="showTodNFTIDs"></span>
                </p>
                <p className="col">
                    <b>Female TOD Balance: </b>
                    <span className="showFemaleTodNFTBalance"></span>
                </p>
                <p className="col">
                    <b>Female TOD IDs: </b>
                    <span className="showFemaleTodNFTIDs"></span>
                </p>
                <p className="col">
                    <b>Total Female TOD Minted: </b>
                    <span className="row">
                    <span className="showTotalPortalTODSupply"></span> &nbsp; / 6000
                    </span>
                </p>
            </div>
            <div className="oddyMintGroup">
                <h2 className="subhead">Mint With Oddy:</h2>
                {selectAllOddysButton()} {deselectAllOddysButton()}
                <p>
                    <span className="loadingOddys" id="loadingOddysID"></span>
                </p>
                <p>
                    <b><span className="noOddysFound"></span></b>
                </p>
                <p>
                    <span className="todNFTsStakedImages" id="todNFTsStakedImagesID"></span>
                </p>
                {claimWithOddyButton()}
            </div>
            <div className="publicMintGroup">
                <h2 className="subhead">Public Mint:</h2>
                <p className="col">
                    <b>Total Public Minted: </b>
                    <span className="row">
                    <span className="showTotalPublicPortalTODSupply"></span> &nbsp;
                    / 1000
                    </span>
                </p>
                <p className="col">
                    <b>Mint Amount: </b>
                </p>
                <span className="row">
                    <input className="TextInput" id="mintNFTAmountID" type="number" step="1" min="1" defaultValue="0"></input>
                    {MintPublicButton()}
                </span>
            </div>
            <div className="claimedCheckGroup">
                <h2 className="subhead">Oddy Claim Checker:</h2>
                <span className="col">
                    <div id="thumb-cont"> ? </div>
                </span>
                <span className="row">
                    <p className="checkResult"> Enter the token ID to check whether the Oddy has been claimed yet.</p>
                </span>
                <span className="row">
                    <input className="TextInput" id="idInput" type="number" step="1" min="1" max="5000" defaultValue="1"></input>
                    {checkClaimedButton()}
                </span>
            </div>
            <div className="row footer-btns">
                {disconnectWalletButton()}
            </div>
        </div>
    </div>
    </>
    );
}

export default App;
